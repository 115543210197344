import firebase from 'firebase'

import createFirebaseId from '@/filters/create_firebase_id'

export default {
    namespaced: true,
    state: {

    },
    filters: {
        createFirebaseId,
    },
    mutations: {

    },
    actions: {
        addCustomerToDB (_, payload) {
            firebase.firestore()
                .collection('customers')
                .doc(`${payload.id}`)
                .set(payload)
        },
        updateCustomerPayDate (_, payload) {
            let firstOrSecond

            if (payload.name.includes('First')) firstOrSecond = 'firstPayment'
            if (payload.name.includes('Second')) firstOrSecond = 'secondPayment'

            firebase.firestore()
                .collection('customers')
                .doc(`${payload.id}`)
                .set({
                    [`${firstOrSecond}`]: {
                        paidAt: payload.paidAt,
                        paid: true,
                    }
                }, { merge: true })
        },
    },
}

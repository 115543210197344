<template>
    <div class="footer">
        <router-link to="/" aria-label="Webby Apps logo">
            <img src="/img/logo/webbyapps.webp" alt="" class="footer__logo">
        </router-link>

         <div class="footer__lists">
             <div class="footer__list">
                <ul>
                    <li>
                        <svg-icon icon="footer/email" />

                        <a href="mailto: contact@webbyapps.io">
                            contact@webbyapps.io
                        </a>
                    </li>

                    <li>
                        <svg-icon icon="footer/phone" />

                        <a href="tel: +359887692290">
                            +359 887 692 290
                        </a>
                    </li>

                    <li>
                        <svg-icon icon="footer/facebook" />

                        <a href="https://www.facebook.com/webbyappsio/" rel="noopener" target="_blank">
                            Webby Apps
                        </a>
                    </li>

                    <li>
                        <svg-icon icon="footer/terms" />

                        <router-link to="/terms">
                            {{
                                i18n.footerLink
                            }}
                        </router-link>
                    </li>
                </ul>

                <img
                    src="/img/logo/webbyapps-mascot-mobile.png"
                    alt=""
                >
             </div>
         </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import SvgIcon from '@/components/SvgIcon'

export default {
    name: 'Footer',
    components: {
        SvgIcon,
    },
    computed: {
        ...mapState(['locales', 'device']),
        i18n() {
            return this.$i18n.messages[this.locales.selected].terms
        },
    },
}
</script>

<style lang="scss">
.footer {
    position: relative;
    margin: 0 -16px;
    padding: 16px;
    color: #8e8e8e;
    background-color: #d1d1d1;
    box-shadow: #57555561 0 -4px 10px;

    @media (min-width: 768px) {
        display: flex;
    }

    @media (min-width: 1024px) {
        padding: 24px 48px;
    }

    &__logo {
        position: absolute;
        top: 24px;
        width: 130px;

        @media (min-width: 1300px) {
            width: 180px;
        }
    }

    &__lists {
        display: flex;
        position: relative;
        justify-content: space-between;

        @media (min-width: 768px) {
            width: 100%;
        }
    }

    &__list {
        display: flex;
        margin: 0 auto;
        flex-direction: column;

        @media (min-width: 768px) {
            margin: 0 auto 0 0;
        }

        @media (min-width: 1300px) {
            margin: auto;
        }

        img {
            width: 100%;

            @media (min-width: 768px) {
                position: absolute;
                top: -6px;
                right: 0;
                width: 180px;
            }

            @media (min-width: 1024px) {
                top: 50%;
                right: 108px;
                transform: translateY(-50%);
            }
        }

        p {
            font-size: 14px;
        }

        a {
            color: #1f94d3;
            font-size: 20px;
            text-decoration: none;

            @media (min-width: 768px) {
                font-size: 16px;
            }

            @media (min-width: 1300px) {

                font-size: 18px;
            }
        }

        ul {
            display: flex;
            margin: 72px auto 0;
            flex-direction: column;

            @media (min-width: 768px) {
                flex-direction: row;
            }

            @media (min-width: 1024px) {
                margin-top: 54px;
            }

            @media (min-width: 1300px) {
                margin-top: 30px;
            }
        }

        li {
            display: flex;
            margin-bottom: 24px;
            flex-direction: column;
            text-align: center;

            @media (min-width: 768px) {
                margin-right: 12px;
                margin-bottom: 0;
            }

            @media (min-width: 1024px) {
                margin-right: 24px;
            }

            .svg-container {
                display: block;
                margin-bottom: 12px;
                font-size: 30px;

                @media (min-width: 768px) {
                    font-size: 20px;
                }

                @media (min-width: 1024px) {
                    font-size: 24px;
                }

                @media (min-width: 1300px) {
                    font-size: 30px;
                }
            }
        }
    }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import firebase from 'firebase'
import "firebase/firestore"
import router from '@/router'
import axios from 'axios'
// import i18n from './../i18n'

import admin from './modules/admin'

import gmapsInit from '@/utils/gmaps'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
})

Vue.use(Vuex)
Vue.config.devtools = true

export default new Vuex.Store({
    modules: {
        admin,
    },
    state: {
        device: null,
        locales: {
            selected: 'en',
            lang: [
                'dk',
                'bg',
                'en',
            ],
            firstTimeWithAuto: false,
        },
        firebase: null,
        role: null,
        isWindows: navigator.platform.indexOf('Win') > -1,
        isAndroid: /(android)/i.test(navigator.userAgent),
        customers: null,
        showModal: false,
        modalContent: null,
        firebaseApi: process.env.NODE_ENV === 'production'
            ? process.env.VUE_APP_FIREBASE_URL
            : process.env.VUE_APP_FIREBASE_URL_LOCAL,
    },
    mutations: {
        setDevice(state, payload) {
            state.device = payload
        },
        setLocale(state, payload) {
            state.locales.selected = payload
        },
        setFirebaseUser(state, firebaseUser) {
            state.firebase = firebaseUser
        },
        setUserRole(state, payload) {
            state.role = payload
        },
        setCustomers(state, payload) {
            state.customers = payload
        },
        setModal(state) {
            state.showModal = !state.showModal
        },
        setModalContent(state, payload) {
            state.modalContent = payload
        },
        setFirstTimeWithAuto(state) {
            state.locales.firstTimeWithAuto = true
        },
    },
    actions: {
        setDevice({ commit }) {
            const { innerWidth: width } = window

            switch (true) {
                case (width >= 1300):
                    return commit('setDevice', 'desktop-higher')
                case (width >= 1024):
                    return commit('setDevice', 'desktop-middle')
                case (width >= 768):
                    return commit('setDevice', 'desktop-lower')
                case (width >= 414):
                    return commit('setDevice', 'mobile-higher')
                case (width >= 375):
                    return commit('setDevice', 'mobile-middle')
                default:
                    return commit('setDevice', 'mobile')
            }
        },
        loginWithFirebase({ state, commit }, firebaseUser) {
            firebase.auth().signInWithEmailAndPassword(firebaseUser.email, firebaseUser.password)
                .then((response) => {
                    commit('setFirebaseUser', response.user)

                    firebase.firestore()
                        .collection('users')
                        .doc(`${state.firebase.uid}`)
                        .get()
                        .then(doc => {
                            if (doc.exists) {
                                commit('setUserRole', doc.data().role)
                                router.push('/admin').catch(error => console.log(error))
                            }
                            else  console.log('doc doesn\'t exist')

                        })
                        .catch(error => console.log('Get users error', error))
                })
                .catch((error) => {
                    console.error('Error loginWithFirebase', error)
                })
        },
        getCustomers({ commit }) {
            firebase.firestore()
                .collection('customers')
                .get()
                .then(snapshot => {
                    let customers = []

                    snapshot.forEach(doc => {
                        customers.push(doc.data())
                    })

                    commit('setCustomers', customers)
                })
                .catch(error => console.log('Get users error', error))
        },
        toggleModal({ commit }, payload) {
            commit('setModal', payload)
        },
        changeLocale({ commit }, payload) {
            commit('setLocale', payload)

            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 0)

            location.reload()
        },
        getCountryCode({ state, dispatch, commit }) {
            axios
                .get(`${state.firebaseApi}/getCountryCode`)
                .then(async response => {
                    let lat = response.data.split(',')[0]
                    let lng = response.data.split(',')[1]

                    const google = await gmapsInit()

                    const geocoder = new google.maps.Geocoder()
                    const latlng = new google.maps.LatLng(lat, lng)

                    geocoder
                        .geocode({ location: latlng })
                        .then(result => {
                            let firstResult = result.results[0]

                            if (firstResult) {
                                let countryCode = firstResult.address_components
                                    .find(item => item.short_name === 'BG' || item.short_name === 'DK').short_name

                                if (countryCode === 'DK') {
                                    commit('setFirstTimeWithAuto')

                                    dispatch('changeLocale', 'dk')
                                } else if (countryCode === 'BG') {
                                    commit('setFirstTimeWithAuto')

                                    dispatch('changeLocale', 'bg')
                                } else {
                                    commit('setFirstTimeWithAuto')
                                }
                            }
                        })
                })
                .catch(error => console.log('geo error', error))
        },
    },
    getters: {
    },
    plugins: [
        vuexLocal.plugin,
    ],
})

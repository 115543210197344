import firebase from "firebase/app"
import "firebase/analytics"

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'

Vue.use(Vuelidate)
Vue.use(VueMeta)

Vue.config.productionTip = false

// Don't make devtools being visible in prod
if (process.env.NODE_ENV === 'production') Vue.config.devtools = false

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)

setTimeout(() => {
    firebase.analytics()
}, 3 * 1000)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')

<template>
  <div id="app">
      <navigation-top />

      <router-view class="view"></router-view>

      <contact-widget />

      <footer-component />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import NavigationTop from '@/components/NavigationTop'
import FooterComponent from '@/components/FooterComponent'
import ContactWidget from '@/components/ContactWidget'

export default {
    name: 'App',
    components: {
        NavigationTop,
        FooterComponent,
        ContactWidget,
    },
    data() {
        return {
            isWindows: navigator.platform.indexOf('Win') > -1,
        }
    },
    computed: {
        ...mapState(['locales']),
    },
    methods: {
        ...mapActions(['setDevice', 'getCountryCode'])
    },
    watch: {
        $route() {
            window.scrollTo(0, 0)
        },
    },
    created() {
        if (!this.locales.firstTimeWithAuto) {
            this.getCountryCode()
        }
    },
    mounted() {
        // Add scroll bar class on body
        if (this.isWindows) document.body.classList.add('windows-scroll-bar')

        this.setDevice()
    },
}
/* eslint-disable */
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;900&display=swap');

body {
    margin: 0;
    padding: 0;
    overflow: auto;
    background-color: #fbfbfb;
}

h1 {
    margin: 0 0 12px;
    font-size: 18px;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 20px;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
}

h2 {
    display: block;
    margin: 0 0 36px;
    font-size: 30px;
    text-align: center;

    @media (min-width: 768px) {
        margin: 0 0 48px;
        font-size: 28px;
    }
}

a {
    outline: none;
    color: #0068ef;
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: 1024px) {
        &:hover {
            text-decoration: underline;
        }
    }
}

button {
    &:hover {
        cursor: pointer;
    }
}

label {
    user-select: none;
}

ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

input {
    &:hover {
        cursor: pointer;
    }
}

textarea {
    font-family: 'Montserrat', sans-serif;
}

input, textarea {
    &::placeholder {
        color: #d8d5d5;
    }
}

#app {
    min-height: 100%;
    padding: 0 16px;
    // padding-top: 54px; // Navigation height
    overflow: hidden;
    color: #434343;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (min-width: 1300px) {
        width: 1300px;
        margin: auto;
        box-shadow: rgba(0, 0, 0, .6) 0 42px 24px;
    }
}

.windows-scroll-bar {
    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

.view {
    // padding-top: 60px;

    // @media (min-width: 768px) {
    //     max-width: 700px;
    //     margin: 48px auto 0;
    // }

    // @media (min-width: 1024px) {
    //     max-width: 900px;
    // }

    // @media (min-width: 1200px) {
    //     max-width: 1200px;
    // }
}
</style>

<template>
    <div class="contact-widget">
        <button
            @click="isOpen = !isOpen"
            class="contact-widget__button"
            :class="{ 'contact-widget__button--open': isOpen }"
        >
            <svg-icon icon="contact-widget/open-contact" />
        </button>

        <transition name="slide">
            <ul
                v-if="isOpen"
                class="contact-widget__items"
            >
                <li
                    v-for="item in items"
                    :key="item.option"
                    class="contact-widget__item"
                    @click="handleClick(item.option)"
                >
                    <a
                        :href="item.link ? item.link : false"
                        :rel="
                            !item.link.includes('tel') && !item.link.includes('viber')
                                ? 'noopener'
                                : ''
                        "
                        :target="
                            !item.link.includes('tel') && !item.link.includes('viber')
                                ? '_blank'
                                : ''
                        "
                    >
                        <svg-icon :icon="`contact-widget/${item.icon}`" />
                    </a>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import SvgIcon from '@/components/SvgIcon'

export default {
    name: 'ContactWidget',
    components: {
        SvgIcon,
    },
    data() {
        return {
            isOpen: false,
            button:                 {
                option: 'contact',
                link: '',
                icon: 'open-contact',
            },
        }
    },
    computed: {
        ...mapState(['device', 'isAndroid', 'isWindows']),
        items() {
            return [
                {
                    option: 'messenger',
                    link: 'https://m.me/webbyappsio',
                    icon: 'messenger',
                },
                {
                    option: 'viber',
                    link: 'viber://chat?Number=%2B359887692290',
                    icon: 'viber',
                },
                {
                    option: 'phone',
                    link: 'tel: +359887692290',
                    icon: 'phone',
                },
                {
                    option: 'contact',
                    link: '',
                    icon: 'contact-form',
                },
                {
                    option: 'close',
                    link: '',
                    icon: 'close',
                },
            ]
        },
    },
    methods: {
        formattedItems() {
            let items

            if (this.isAndroid) {
                // For some reason Viber doesn't even know, Android will only open with add prefix - fucking retards
                // For contacting us through Viber, clients need the app installed
                this.items.find(item => item.option === 'viber').link = 'viber://add?number=359887692290'

                items = this.items
            } else {
                items = this.items
            }

            return items
        },
        handleClick(option) {
            if (option === 'close') this.isOpen = false

            if (option === 'contact') {
                this.isOpen = false

                document.getElementById('contact').scrollIntoView()

                if (this.device.includes('mobile')) {
                    window.scrollBy(0, -60)
                } else if (this.device.includes('desktop-lower')) {
                    window.scrollBy(0, 0)
                } else if (this.device.includes('desktop-higher')) {
                    window.scrollBy(0, 0)
                } else if (this.device.includes('desktop')) {
                    window.scrollBy(0, -70)
                }
            }
        },
    },
    mounted() {
        this.formattedItems()
    },
}
</script>

<style lang="scss">
.contact-widget {
    &__button {
        position: fixed;
        z-index: 10;
        right: 12px;
        bottom: 12px;
        width: 80px;
        height: 60px;
        border: none;
        border-radius: 50px;
        background-color: transparent;
        transition: .5s;

        @media (min-width: 768px) {
            right: 36px;
            bottom: 24px;
            height: 80px;
        }

        &--open {
            opacity: 0;
            transition: .5s;
        }

        .svg-container {
            width: 60px;
            height: 60px;

            @media (min-width: 768px) {
                width: 80px;
                height: 80px;
            }
        }
    }

    &__items {
        position: fixed;
        z-index: 1;
        right: 22px;
        bottom: 11px;

        @media (min-width: 768px) {
            right: 36px;
            bottom: 24px;
        }
    }

    &__item {
        position: relative;
        width: 60px;
        height: 60px;
        margin: 6px 0;
        border-radius: 50%;

        @media (min-width: 768px) {
            width: 80px;
            height: 80px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a {
            position: absolute;
            top: 50%;
            right: 50%;
            margin: 0;
            transform: translate(50%, -50%);
            color: white;
        }
    }

    .svg-container {
        font-size: 60px;

        @media (min-width: 768px) {
            font-size: 80px;
        }
    }
}

.slide-leave-active, .slide-enter-active {
    transition: all .8s ease;
}

.slide-enter, .slide-leave-to {
    transform: translateY(100%);
    opacity: 0;
    transition-duration: 1s;
}
</style>

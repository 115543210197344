import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

const routeOptions = [
    {
        path: '/',
        name: 'Home',
    },
    {
        path: '/projects',
        name: 'Projects',
    },
    {
        path: '/features',
        name: 'Features',
    },
    {
        path: '/contact',
        name: 'Contact',
    },
    {
        path: '/login',
        name: 'Login',
        beforeEnter: (to, from, next) => {
            if (store.state.role === 'admin') next({ name: 'Admin' })
            else next()
        },
    },
    {
        path: '/admin',
        name: 'Admin',
        beforeEnter: (to, from, next) => {
            if (store.state.role === 'admin') next()
            else next({ name: 'Home' })
        },
    },
    {
        path: '/terms',
        name: 'Terms',
    },
]

const routes = routeOptions.map(route => {
    return {
        ...route,
        component: () => import(/* webpackChunkName: "[request]" */`@/views/${route.name}.vue`),
    }
})

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router

<template>
    <div class="navigation-top">
        <router-link to="/" class="webby-logo" aria-label="Webby Apps logo">
            <img src="/img/logo/webbyapps.webp" alt="">
        </router-link>

        <!-- MOBILE -->
        <svg-icon
            v-if="device && device.includes('mobile') || device === 'desktop-lower'"
            @click.native.stop="menuOpen = !menuOpen"
            icon="menu"
            class="navigation-top__menu"
        />

        <ul
            v-else
            class="navigation-top__menu-desktop"
            :style="`right: ${right}px`"
        >
            <li
                v-for="item in navigationList"
                :key="item.title"
            >
                <router-link :to="item.route">
                    {{ item.title }}
                </router-link>
            </li>
        </ul>

        <transition name="slide">
            <ul v-if="menuOpen" class="navigation-top__menu-list">
                <li
                    v-for="item in navigationList"
                    :key="item.title"
                >
                    <router-link :to="item.route" @click.native="menuOpen = false">
                        {{
                            item.title
                        }}
                    </router-link>
                </li>
            </ul>
        </transition>

        <select-language />
    </div>
</template>

<script>
import { mapState } from 'vuex'

import SelectLanguage from '@/components/SelectLanguage'
import SvgIcon from '@/components/SvgIcon'

export default {
    name: 'NavigationTop',
    components: {
        SelectLanguage,
        SvgIcon,
    },
    data() {
        return {
            menuOpen: false,
            right: null,
        }
    },
    computed: {
        ...mapState(['device']),
        navigationList() {
            return [
                {
                    title: this.$t('navigationTop.links')[0],
                    route: '/',
                },
                {
                    title: this.$t('navigationTop.links')[1],
                    route: '/projects',
                },
                {
                    title: this.$t('navigationTop.links')[2],
                    route: '/features',
                },
                {
                    title: this.$t('navigationTop.links')[3],
                    route: '/contact',
                },
            ]
        },
    },
    mounted() {
        document.getElementById('app').addEventListener('click', () => {
            this.menuOpen = false
        })

        setTimeout(() => {
            document.getElementById('select-language').addEventListener('click', () => {
                this.menuOpen = false
            })
        }, 1000 * 3)

        if (window.innerWidth >= 1200) {
            this.right = ((window.innerWidth - 1200) / 2) + 54
        }
    },
}
</script>

<style lang="scss">
.navigation-top {
    display: flex;
    position: fixed;
    z-index: 10;
    top: -1px;
    left: 0;
    width: calc(100vw - 32px);
    height: 54px;
    padding: 0 16px;
    justify-content: space-between;
    backdrop-filter: blur(3px); // Won't work on Firefox - too bad, noobs
    // transform: scale(.99);
    background-color: rgba(97, 96, 98, .2);

    @media (min-width: 768px) {
        padding: 8px 16px;
    }

    @media (min-width: 1024px) {
        width: calc(100vw - 96px);
        height: auto;
        padding: 16px 48px;
    }

    @media (min-width: 1300px) {
        left: 50%;
        width: calc(1300px - 100px);
        height: auto;
        padding: 24px 66px;
        transform: translateX(-50%);
    }

    &__menu {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        font-size: 26px;

        .line-stroke {
            stroke: white;
        }

        &--company {
            .line-stroke {
                stroke: black;
            }
        }

        &--community {
            .line-stroke {
                stroke: #4d4d4d;
            }
        }
    }

    &__menu-list {
        position: absolute;
        z-index: 2;
        top: 48px;
        right: 16px;
        width: 130px;
        margin: 0;
        padding: 12px;
        overflow: hidden;
        border-radius: 6px;
        background-color: #4d4d4d;
        list-style-type: none;
        transform-origin: top;
        transition: transform .4s ease-in-out;

        li {
            margin-bottom: 12px;
            text-align: center;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: white;
            }
        }
    }

    &__menu-desktop {
        display: flex;
        margin: auto 54px auto auto;
        margin-right: 54px;

        a {
            color: white;
        }

        li {
            margin-right: 24px;
            font-size: 20px;
            font-weight: 700;
        }
    }

    .webby-logo {
        margin: 0 auto 0 0; // TODO: Remove line when we live in BG

        img {
            width: 130px;
            height: 100%;
            object-fit: contain;

            @media (min-width: 1300px) {
                width: 180px;
            }
        }
    }

    .menu {
        margin: auto 0;
        font-size: 20px;

        .a {
            fill: #066ca2;
        }
    }
}

.router-link-exact-active {
    color: #2c83a0 !important;
    text-decoration: underline;
}

.slide-enter, .slide-leave-to {
    transform: scaleY(0);
}
</style>

<template>
    <div
        class="select-language"
        :class="{'select-language--open': selectingLanguage}"
        :style="`right: ${right}px`"
    >
        <img
            id="select-language"
            :src="`/img/flags/${locales.selected}.png`"
            alt=""
            @click.stop="selectingLanguage = !selectingLanguage"
            width="36"
            height="20"
        >

        <transition name="slide">
            <ul v-if="selectingLanguage">
                <li
                    v-for="item in filteredLanguages"
                    :key="item"
                >
                    <img
                        :src="`/img/flags/${item}.png`"
                        alt=""
                        @click="changeLocale(item, selectingLanguage = false)"
                        width="36"
                        height="20"
                    >
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'SelectLanguage',
    data() {
        return {
            selectingLanguage: false,
            right: null,
        }
    },
    computed: {
        ...mapState(['locales']),
        filteredLanguages() {
            return this.locales.lang.filter(item => item !== this.locales.selected)
        },
    },
    methods: {
        ...mapActions(['changeLocale']),
    },
    mounted() {
        document.getElementById('app').addEventListener('click', () => {
            this.selectingLanguage = false
        })

        if (document.getElementsByClassName('navigation-top__menu')[0]) {
            document.getElementsByClassName('navigation-top__menu')[0].addEventListener('click', () => {
                this.selectingLanguage = false
            })
        }

        // if (window.innerWidth >= 1200) {
        //     this.right = (window.innerWidth - 1200) / 2
        // }
    },
}
</script>

<style lang="scss">
.select-language {
    position: absolute;
    top: 12px;
    right: 60px;
    width: 60px;
    cursor: pointer;

    @media (min-width: 768px) {
        top: 20px;
    }

    @media (min-width: 1024px) {
        top: 28px;
        right: 48px;
    }

    @media (min-width: 1300px) {
        top: 48px;
        right: 66px;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
        margin-bottom: 3px;
    }

    img {
        position: relative;
        right: 0;
        margin-bottom: 3px;
        transform: translateX(50%);
        border-radius: 3px;

        @media (min-width: 1024px) {
            margin-bottom: 6px;
        }
    }
}
</style>
